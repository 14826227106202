import React from "react"
import { Link } from "gatsby"
import "./acknowledgement.css"

const Acknowledgement = () => {
  return (
    <div className="acknowledgement">
      <h3 className="text-primary">Thanks for reaching out</h3>
      <p>
        We will be in touch asap to chat about your home loan story, your home
        loan selection, your borrowing power and your best way forward to{" "}
        <Link to="/">Compare n Save</Link>.
      </p>
      <p>Chat Soon...</p>
      <br />
      <p>
        <Link to="/">Compare n Save</Link> | a TAG Finance and Loans Service
      </p>
    </div>
  )
}

export default Acknowledgement
